/* eslint-disable global-require */
import _ from 'lodash';
import getConfig from 'next/config';
// import log from '@/utils/log';
import cookie from 'js-cookie';
import KJUR from 'jsrsasign';

export const isClient = typeof window !== 'undefined';

export function getComponentDisplayName(Component) {
  return Component.displayName || Component.name || 'Unknown';
}

export const validateEmail = email => {
  const re = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  return re.test(String(email).toLowerCase());
};

export const validateMobile = email => {
  const re = /^(\+84|84|0|1){1}([0-9]{7,10})\b$/i;
  return re.test(String(email).toLowerCase());
};

export const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

export const makeFlatten = data =>
  data.reduce((acc, record) => {
    acc.push(record);
    if (record.children) {
      record.children.forEach(item => {
        acc.push(item);
      });
    }
    return acc;
  }, []);

export const findRoute = (name, data) => _.find(data, item => item.path === name);

export function userid() {
  const cookieToken = cookie.get('token');
  const token = cookieToken !== 'undefined' ? cookieToken : null;
  const decodedToken = KJUR.jws.JWS.parse(token);
  if (decodedToken && decodedToken.payloadObj.userId) {
    const { userId } = decodedToken.payloadObj;
    return userId;
  }
  return 0;
}
